import { useGetInfoTypesQuery } from '../../app/services/info-types';

const useGetInfosByType = type => {
  const { data, isLoading } = useGetInfoTypesQuery();
  const selectedTypes = (data?.data || []).filter(el => el?.type === type);

  return [selectedTypes, isLoading];
};

export default useGetInfosByType;
