import axios from 'axios';
import { API_ROOT } from 'configs/env-vars';
import provider from 'services/main-client';

export const [addBase64UploadApi] = provider('/file-storage', ['add']);
export const [deleteUploadApi] = provider('/upload/files/', ['delete']);

export const addUploadApi = values => {
  const formData = new FormData();
  formData.append('files', values);
  return axios
    .post(`${API_ROOT}/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res)
    .catch(() => null);
};
