/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  docsIsValid: false,
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocsAreValid: (state, { payload }) => {
      state.docsAreValid = payload;
    },
  },
});

export const { setDocsAreValid } = documentsSlice.actions;

export default documentsSlice.reducer;
