import styled, { css } from 'styled-components';
import { InputNumber as inputNumber } from 'antd';
import { colors } from '../../../constants/colors';

const InputNumber = styled(inputNumber)`
  width: 100%;
  padding: 4px 20px 3px;
  height: auto;
  border-radius: 40px !important;
  border: 1px solid #d9d9d9;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  & input {
    height: 100% !important;
  }

  & .ant-input-number-affix-wrapper,
  & .ant-input-number-input-wrap {
    border: none;
    height: 100% !important;
  }

  & .ant-input-number-wrapper > input,
  & .ant-input-number-wrapper > .ant-input-number-affix-wrapper {
    outline: none !important;
    border: none;
    box-shadow: none !important;

    &:focus {
      outline: none !important;
      border: none;
      box-shadow: none !important;
    }
  }
  & .ant-input-number-group-addon {
    background: transparent;
    padding: 0 0 0 10px !important;
    border: none !important;
  }

  &.ant-input-number-input {
    height: 100% !important;
    background: #fff;
    box-sizing: border-box;
    border: 1px solid #9ea09f;
    padding: 0 15px;
    font-weight: 300;
    font-size: 14px;
    color: #5f6982;
    width: 100%;
  }

  &:hover {
    border-color: ${colors.main_color}!important;
  }
  &:focus,
  &.ant-input-number-focused {
    border-color: #d9d9d9 !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.main_color}2e;
  }
  &.ant-input-number-disabled {
    background: #fff;
    opacity: 1;
    input {
      color: #00000096 !important;
      opacity: 1;
    }
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }

  & input[disabled] {
    color: #00000096;
  }

  &.ant-input-number-affix-wrapper .ant-input-number {
    padding: 6px 0 !important ;
  }

  &.ant-input-number-affix-wrapper-disabled {
    background-color: #fff;
  }

  ${props =>
    props.fz &&
    css`
      .ant-input-number{
        font-size: ${props.fz} !important;
    `}

  ${props =>
    props.color_type === 'blue' &&
    css`
      border: 1px solid #000080;
      &.ant-input-number-number-disabled {
        color: #000080;
        background-color: #fff;
        border: 1px solid #000080;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

    ${props =>
    props.input_number_padding &&
    css`
      .ant-input-number {
        padding: ${props.input_number_padding};
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border} !important;
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
`;

const CustomInputNumber = ({ isPrice, ...props }) => (
  <InputNumber
    {...props}
    {...(isPrice
      ? {
          formatter: value => {
            if (!value) return '';
            return ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
          parser: value => {
            if (!value) return '';
            return value.replace(/\$\s?|(,*)/g, '');
          },
        }
      : {})}
  />
);
export default CustomInputNumber;
