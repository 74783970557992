import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import provider from '../../services/main-client';
import {
  applicationsEndpoint,
  documentsEndpoint,
  documentsTypesEndpoint,
} from '../../constants/endpoints';
import {
  setApplicationDataIsStored,
  setApplicationLoading,
  storeOriginApplication,
} from '../../app/slices/application';
import useApiCall from './useApiCall';
import { setDocsAreValid } from '../../app/slices/documents';

const [applicationApi] = provider(applicationsEndpoint, ['get']);

const checkDocValid = (documents, documentTypes, applicantsCount) => {
  return documentTypes?.data?.every(type => {
    return (
      type?.numberOfRequiredDocuments <=
      (documents?.data?.find(
        item =>
          item?.documentTypeId?.id === type?.id && type?.applicantIndex === item?.applicantIndex
      )?.documentFiles?.length || 0)
    );
  });
};

const [documentsApi] = provider(documentsEndpoint, ['get']);
const [documentTypesApi] = provider(documentsTypesEndpoint, ['get']);

const useStoreApplicationById = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, , applicationDocumentsCall] = useApiCall(documentsApi);
  const [, , documentTypesCall] = useApiCall(documentTypesApi);

  const storeData = (
    customerId,
    applicationId,
    callBack = () => {},
    force,
    hideLoading = false
  ) => {
    if (
      !['/new-mortgage', '/mortgage-renewal', '/refinance-mortgage', '/wp-quote'].includes(
        pathname
      ) ||
      force
    ) {
      dispatch(setApplicationLoading(!hideLoading));
      applicationApi({ customerId, populate: '*', sort: ['updatedAt:DESC'] })
        .then(res => {
          setTimeout(() => {
            dispatch(setApplicationLoading(false));
            dispatch(setApplicationDataIsStored(true));
          }, 1000);

          const appList = res?.data?.data;
          if (!appList?.length) {
            navigate('/get-quote');
          } else if (!applicationId) {
            navigate('/');
          }
          callBack();
          const selectedApplication = appList?.find(el => el.id === +applicationId);
          if (selectedApplication) {
            dispatch(storeOriginApplication(selectedApplication));
            applicationDocumentsCall(
              {
                populate: '*',
                applicationId,
                pageSize: 300,
              },
              docs => {
                documentTypesCall({ applicationId }, types => {
                  const isValid = checkDocValid(
                    docs,
                    types,
                    (selectedApplication?.data?.['applicants-property']?.applicants || [])?.length
                  );
                  dispatch(setDocsAreValid(isValid));
                });
              }
            );
          } else if (applicationId) {
            localStorage.removeItem('applicationId');
          }
        })
        .catch(() => {
          setTimeout(() => {
            dispatch(setApplicationLoading(false));
            dispatch(setApplicationDataIsStored(true));
          }, 1000);
        });
    }
  };

  return [storeData];
};

export default useStoreApplicationById;
