import { createCustomApi } from '../../configs/rtk-query';

export const settingsApi = createCustomApi({
  reducerPath: 'settings',
  endpoints: builder => ({
    getAppSettings: builder.query({
      query: (params = {}) => ({
        url: '/settings',
        params,
      }),
    }),
  }),
});

export const { useGetAppSettingsQuery } = settingsApi;
