import styled, { css } from 'styled-components';
import { colors } from 'constants/colors';

const SpinnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .loader {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    width: 100px;
    height: 100px;

    > div {
      position: relative;
      width: 80%;
      height: 80%;
      border-radius: 8px;
      background: ${colors.main_color};
      transform: scale(0);
      transform-origin: center center;
      animation: loader 2s infinite linear;

      &:nth-of-type(7) {
      }

      &:nth-of-type(1),
      &:nth-of-type(5),
      &:nth-of-type(9) {
        animation-delay: 0.4s;
      }

      &:nth-of-type(4),
      &:nth-of-type(8) {
        animation-delay: 0.2s;
      }

      &:nth-of-type(2),
      &:nth-of-type(6) {
        animation-delay: 0.6s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.8s;
      }
    }
  }

  @keyframes loader {
    0% {
      transform: scale(0) rotate(180deg);
    }
    20% {
      transform: scale(1) rotate(-90deg);
    }
    60% {
      transform: scale(1) rotate(-90deg);
    }
    100% {
      transform: scale(0) rotate(180deg);
    }

    ${props =>
      props.size === 'fullscreen' &&
      css`
        position: fixed;
        top: 0;
        z-index: 9999999;
        background: #fff;
      `};
  }
`;

const PageSpinner = ({ isFullScreen }) => {
  return (
    <SpinnerWrapper size={isFullScreen ? 'fullscreen' : ''}>
      <div className='loader'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </SpinnerWrapper>
  );
};

export default PageSpinner;
