export const protectedRouts = {
  customer: {
    applications: {
      label: 'My Applications',
      pageTitle: 'My Applications',
      key: 'applications',
      pathname: '/',
      component: 'pages/customer/applications.page',
    },
    getQuote: {
      label: 'Get Quote',
      pageTitle: 'Get Quote',
      pathname: '/get-quote',
      key: 'getQuote',
      hideInSidebar: true,
      component: 'pages/mortgage/get-quote.page',
    },
    newMortgage: {
      label: 'New Mortgage',
      pageTitle: 'New Mortgage',
      pathname: '/new-mortgage',
      key: 'newMortgage',
      hideInSidebar: true,
      component: 'pages/mortgage/new-mortgage.page',
    },
    mortgageRenewal: {
      label: 'Mortgage Renewal',
      pageTitle: 'Mortgage Renewal',
      pathname: '/mortgage-renewal',
      key: 'mortgageRenewal',
      hideInSidebar: true,
      component: 'pages/mortgage/mortgage-renewal.page',
    },
    refinanceMortgage: {
      label: 'Refinance Mortgage',
      pageTitle: 'Refinance Mortgage',
      pathname: '/refinance-mortgage',
      key: 'refinanceMortgage',
      hideInSidebar: true,
      component: 'pages/mortgage/refinance-mortgage.page',
    },
    wpQuote: {
      label: 'WP Quote',
      pageTitle: 'WP Quote',
      pathname: '/wp-quote',
      key: 'wpQuote',
      hideInSidebar: true,
      component: 'pages/wp-quote.page',
    },
    myProfile: {
      label: 'My Profile',
      pageTitle: 'My Profile',
      pathname: '/my-profile',
      key: 'myProfile',
      hideInSidebar: true,
      component: 'pages/customer/my-profile.page',
    },
    applicantAndProperty: {
      label: 'Applicants & Property',
      pageTitle: 'Applicants & Property',
      key: 'applicantAndProperty',
      items: {
        applicants: {
          label: 'New Applicant',
          pageTitle: 'Applicants',
          key: 'applicants',
          parentkeys: ['applicantAndProperty'],
          items: {
            identification: {
              parentkeys: ['applicantAndProperty', 'applicants_$index$'],
              label: 'Identification',
              pageTitle: 'Identification',
              originPath: '/identification',
              pathname: '/identification/:applicantNumber',
              key: 'identification',
              component: 'pages/customer/application-and-property/identification.page',
            },
            registeredAddress: {
              parentkeys: ['applicantAndProperty', 'applicants_$index$'],
              label: 'Registered Address',
              pageTitle: 'Registered Address',
              originPath: '/registered-address',
              pathname: '/registered-address/:applicantNumber',
              key: 'registeredAddress',
              component: 'pages/customer/application-and-property/registration-address.page',
            },
            income: {
              parentkeys: ['applicantAndProperty', 'applicants_$index$'],
              label: 'Income',
              pageTitle: 'Income',
              originPath: '/income',
              pathname: '/income/:applicantNumber',
              key: 'income',
              component: 'pages/customer/application-and-property/income.page',
            },
            // otherIncome: {
            //   parentkeys: ['applicantAndProperty', 'applicants_$index$'],
            //   label: 'Other Income',
            //   pageTitle: 'Other Income',
            //   originPath: '/other-income',
            //   pathname: '/other-income/:applicantNumber',
            //   key: 'otherIncome',
            //   component: 'pages/customer/application-and-property/other-income.page',
            // },
            ownedProperties: {
              parentkeys: ['applicantAndProperty', 'applicants_$index$'],
              label: 'Existing property details',
              pageTitle: 'Existing property details',
              originPath: '/owned-properties',
              pathname: '/owned-properties/:applicantNumber',
              key: 'otherIncome',
              component: 'pages/customer/application-and-property/owned-properties.page',
            },
            // bankingDetails: {
            //   parentkeys: ['applicantAndProperty', 'applicants_$index$'],
            //   label: 'Banking Details',
            //   pageTitle: 'Banking Details',
            //   originPath: '/banking-details',
            //   pathname: '/banking-details/:applicantNumber',
            //   key: 'bankingDetails',
            //   component: 'pages/customer/application-and-property/banking-detail.page',
            // },
          },
        },
        targetProperty: {
          parentkeys: ['applicantAndProperty'],
          label: 'New property details',
          pageTitle: 'TargetProperty',
          originPath: '/target-property',
          pathname: '/target-property',
          key: 'targetProperty',
          component: 'pages/customer/application-and-property/target-property.page',
        },
        renewingProperty: {
          parentkeys: ['applicantAndProperty'],
          label: 'Renewing property',
          pageTitle: 'RenewingProperty',
          originPath: '/renewing-property',
          pathname: '/renewing-property',
          key: 'renewingProperty',
          component: 'pages/customer/application-and-property/renewing-property.page',
        },
        refinancingProperty: {
          parentkeys: ['applicantAndProperty'],
          label: 'Refinancing property',
          pageTitle: 'RefinancingProperty',
          originPath: '/refinancing-property',
          pathname: '/refinancing-property',
          key: 'refinancingProperty',
          component: 'pages/customer/application-and-property/refinancing-property.page',
        },
        downPayment: {
          parentkeys: ['applicantAndProperty'],
          label: 'Down payment',
          pageTitle: 'downPayment',
          originPath: '/down-payment',
          pathname: '/down-payment',
          key: 'downPayment',
          component: 'pages/customer/application-and-property/down-payment.page',
        },
        // registrationAddress: {
        //   label: 'Registration address',
        //   pageTitle: 'Registration address',
        //   pathname: '/registration-address',
        //   key: 'registrationAddress',
        //   component: 'pages/customer/application-and-property/registration-address.page',
        // },
      },
    },
    documents: {
      label: 'My Documents',
      pageTitle: 'My Documents',
      key: 'documents',
      pathname: '/documents',
      component: 'pages/customer/documents.page',
    },
    documentsWithNumber: {
      label: 'My Documents',
      pageTitle: 'My Documents',
      key: 'documentsWithNumber',
      pathname: '/documents/:applicantNumber',
      hideInSidebar: true,
      component: 'pages/customer/documents.page',
    },
    viewAppDocument: {
      label: 'Application',
      pageTitle: 'Application',
      key: 'viewAppDocument',
      hideInSidebar: true,
      pathname: '/view-app-document/:appId/:applicantNumber',
      component: 'pages/view-app-document.page',
    },
  },
  agent: {
    dashboard: {
      label: 'Dashboard',
      pageTitle: 'Dashboard',
      key: 'dashboard',
      pathname: '/',
      component: 'pages/agent/dashboard.page',
    },
  },
  admin: {
    customers: {
      label: 'Customers',
      pageTitle: 'Customers',
      key: 'customers',
      pathname: '/',
      component: 'pages/admin/customers.page',
    },
    agents: {
      label: 'Agents',
      pageTitle: 'Agents',
      key: 'agents',
      pathname: '/agents',
      component: 'pages/admin/agents.page',
    },
    appList: {
      label: 'Applications',
      pageTitle: 'Applications',
      key: 'appList',
      pathname: '/applications',
      component: 'pages/admin/applications.page',
    },
    singleApplication: {
      label: 'Single Application',
      pageTitle: 'Single Application',
      key: 'singleApplication',
      hideInSidebar: true,
      pathname: '/applications/:appId/:applicantNumber',
      component: 'pages/admin/single-application.page',
    },
    viewAppDocument: {
      label: 'Application',
      pageTitle: 'Application',
      key: 'viewAppDocument',
      hideInSidebar: true,
      pathname: '/view-app-document/:appId/:applicantNumber',
      component: 'pages/view-app-document.page',
    },
    settings: {
      label: 'Settings',
      pageTitle: 'Settings',
      key: 'settings',
      items: {
        lenders: {
          label: 'Lenders',
          pageTitle: 'Lenders',
          key: 'lenders',
          parentkeys: ['settings'],
          pathname: '/lenders',
          component: 'pages/admin/settings/lenders.page',
        },
        rates: {
          label: 'Rates',
          pageTitle: 'Rates',
          key: 'rates',
          parentkeys: ['settings'],
          pathname: '/rates',
          component: 'pages/admin/settings/rates.page',
        },
        // generalSettings: {
        //   label: 'General Settings',
        //   pageTitle: 'General Settings',
        //   key: 'generalSettings',
        //   parentkeys: ['settings'],
        //   pathname: '/general-settings',
        //   component: 'pages/admin/settings/general-settings.page',
        // },
      },
    },
  },
};

const routes = {
  signIn: {
    label: 'Sign In',
    pageTitle: 'Sign In',
    pathname: '/sign-in',
    key: 'signIn',
    component: 'pages/guest/sign-in.page',
  },
  signUp: {
    label: 'Sign Up',
    pageTitle: 'Sign Up',
    pathname: '/sign-up',
    key: 'signUp',
    component: 'pages/guest/sign-up.page',
  },
  forgotPass: {
    label: 'Forgot Password',
    pageTitle: 'Forgot Password',
    pathname: '/forgot-password',
    key: 'forgotPass',
    component: 'pages/guest/forgot-password.page',
  },
  resetPass: {
    label: 'Reset Password',
    pageTitle: 'Reset Password',
    pathname: '/reset-password',
    key: 'resetPass',
    component: 'pages/guest/reset-password.page',
  },
  getQuote: {
    label: 'Get Quote',
    pageTitle: 'Get Quote',
    pathname: '/get-quote',
    key: 'getQuote',
    component: 'pages/mortgage/get-quote.page',
  },
  newMortgage: {
    label: 'New Mortgage',
    pageTitle: 'New Mortgage',
    pathname: '/new-mortgage',
    key: 'newMortgage',
    component: 'pages/mortgage/new-mortgage.page',
  },
  mortgageRenewal: {
    label: 'Mortgage Renewal',
    pageTitle: 'Mortgage Renewal',
    pathname: '/mortgage-renewal',
    key: 'mortgageRenewal',
    component: 'pages/mortgage/mortgage-renewal.page',
  },
  refinanceMortgage: {
    label: 'Refinance Mortgage',
    pageTitle: 'Refinance Mortgage',
    pathname: '/refinance-mortgage',
    key: 'refinanceMortgage',
    component: 'pages/mortgage/refinance-mortgage.page',
  },
  wpQuote: {
    label: 'WP Quote',
    pageTitle: 'WP Quote',
    pathname: '/wp-quote',
    key: 'wpQuote',
    hideInSidebar: true,
    component: 'pages/wp-quote.page',
  },
  viewAppDocument: {
    label: 'Application',
    pageTitle: 'Application',
    key: 'viewAppDocument',
    hideInSidebar: true,
    pathname: '/view-app-document/:appId/:applicantNumber',
    component: 'pages/view-app-document.page',
  },
};

export const roleDefPaths = {
  customer: '/',
  agent: '/',
  admin: '/',
};

export default routes;
