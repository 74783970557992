/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { Form } from '../index';

const ModalWrapperComp = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(8, 26, 51, 0.4);
  z-index: 102;
  overflow-y: auto;
  backdrop-filter: blur(4px);
  padding: 30px 0 100px 0;
`;

const modalRoot = document.getElementById('modal-root');

const ModalWrapper = ({ children, closeModal }) => {
  const handleClick = event => {
    if (event.target === event.currentTarget) {
      event.stopPropagation();
      closeModal();
    }
  };
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // eslint-disable-next-line no-return-assign
    return () => (document.body.style.overflow = 'scroll');
  });
  return ReactDOM.createPortal(
    <ModalWrapperComp onMouseDownCapture={handleClick}>
      <Form.Provider onFormChange={() => {}}>{children}</Form.Provider>
    </ModalWrapperComp>,
    modalRoot
  );
};

export default ModalWrapper;
