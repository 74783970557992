/* eslint-disable import/no-cycle */
import { Col } from 'antd';
import styled, { css } from 'styled-components';
import WarningIcon from 'assets/custom-icons/WarningIcon';
import { Paragraph } from '../index';

const Wrapper = styled(Col)`
  color: blue;
  border: 1px solid #35c5a4;
  background: #ebfaf6;
  border-radius: 10px;
  margin-top: 24px;
  &::after {
    position: absolute;
    top: -8px;
    /* left: 50px; */
    /* left: 80%; */
    /* right:'0px' */
    content: '';
    width: 15px;
    height: 15px;
    border: 1px solid #35c5a4;
    background: #ebfaf6;
    border-color: transparent #35c5a4 #35c5a4 transparent;
    transform: rotate(225deg);

    ${props =>
      props.is_left &&
      css`
        left: 50px;
      `}

    ${props =>
      props.is_right &&
      css`
        left: 80% !important;
      `}
  }
  display: flex;
  padding: 10px;
  column-gap: 20px;
  align-items: center;
`;

const CustomError = ({ text, isLeft = '50px', isRight, textStyles }) => {
  return (
    <Wrapper className='errorWrapper' is_left={isLeft} is_right={isRight}>
      <Col>
        <WarningIcon />
      </Col>
      <Col style={{ color: '#000' }}>
        <Paragraph fz={14} fw={400} mb={0} style={textStyles}>
          {text}
        </Paragraph>
      </Col>
    </Wrapper>
  );
};

export default CustomError;
