import { notification } from 'antd';
import { APIProvider } from 'digilite-data-providersstrapi.v4';
import routes from 'constants/routes';
import { getAccessToken, emptyState } from '../configs/local-storage';
import { API_ROOT } from '../configs/env-vars';

const statusHandler = {
  on404: () => {
    window.location.pathname = routes.notFound.pathname;
  },
  on403: () => {},
  on401: () => {
    emptyState();
    window.location.href = '/sign-in';
  },
  defaultErrHandler: errorResponse => {
    notification.error({
      message: 'Error',
      description:
        errorResponse?.data?.data?.message?.[0]?.messages?.[0]?.message ||
        (typeof errorResponse?.data?.data?.message === 'string' &&
          errorResponse?.data?.data?.message) ||
        'Something went wrong',
      maxCount: 1,
      duration: 0,
    });
  },
};

const provider = APIProvider({
  APIRoot: API_ROOT,
  headers: {},
  accessToken: getAccessToken(),
  statusHandler,
});

export default provider;
