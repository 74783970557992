import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from 'assets/custom-icons/CloseIcon';
import { Paragraph, Row } from '../atoms';

const ModalContentWrapper = styled.div`
  width: 70%;
  background-color: #fff;
  border-radius: 20px;
  height: max-content;
  transform: scale(0);
  transition: all ease 0.3s;
  overflow: hidden;
  position: relative;
  margin: auto 0;

  ${props =>
    props.open_status === 'open' &&
    css`
      transform: scale(1);
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}!important;
    `}
    ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin}!important;
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width}!important;
    `}
    ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width}!important;
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border}!important;
    `}

    @media (max-width: 762px) {
    width: 90%;
  }
`;

const ModalContent = ({
  title,
  closeModal,
  children,
  customWidth,
  customMaxWidth,
  customMinWidth,
  customMargin,
  headerBG,
  icon = true,
  notification = true,
  hideClose = true,
}) => {
  const [openStatus, setOpenStatus] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setOpenStatus('open');
    }, 0);

    return () => setOpenStatus('');
  }, []);

  return (
    <ModalContentWrapper
      onClick={event => event.stopPropagation()}
      open_status={openStatus}
      width={customWidth}
      margin={customMargin}
      max_width={customMaxWidth}
      min_width={customMinWidth}
    >
      <Row align='middle' justify='space-between' back_color={headerBG}>
        <Paragraph fz={20} fw={700} mb={0} color='#58585A'>
          {title}
        </Paragraph>
        {hideClose && <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />}
      </Row>
      {children}
    </ModalContentWrapper>
  );
};

export default ModalContent;
