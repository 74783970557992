/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
// eslint-disable import/no-extraneous-dependencies
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import provider from 'services/main-client';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import useStoreApplicationById from 'utils/hooks/useStoreApplicationById';
import { protectedRouts } from 'constants/routes';
import {
  Content,
  Layout,
  Menu,
  Sider,
  PageSpinner,
  Button,
  Col,
  Paragraph,
} from 'components/atoms/index';
import DashboardHeader from 'components/molecules/dashboard-header';
import {
  getApplicantsData,
  getApplicationDataIsStored,
  getApplicationLoading,
  getApplicationStatusData,
  getApplicationType,
  getDocsAreValid,
  getFullApplicationData,
  getUser,
  getUserRole,
} from 'app/services/selector-helpers';
import { isNewMortgageCompleted } from 'utils/isNewMortgageCompleted';
import { isRefinanceCompleted } from 'utils/isRefinanceCompleted';
import { isRenewalCompleted } from 'utils/isRenewalCompleted';
import { applicationsEndpoint } from 'constants/endpoints';
import SidebarFooter from 'components/molecules/sidebar-footer';
import HomeSidebar from 'components/molecules/home-sidebar';
import { convertRoutesToItems, submenuKeys } from '../../utils/helpers';
import useGetApplicationControls from '../../utils/hooks/useGetApplicationControls';
import { savedAppId } from '../../configs/local-storage';
import useApiCall from '../../utils/hooks/useApiCall';
import useGetInfosByType from '../../utils/hooks/useGetInfosByType';
import { colors } from '../../constants/colors';
import SubmitSuccessPopup from '../molecules/submit-success-popup';

const [updateApplicationApi] = provider(applicationsEndpoint, ['update'], false);

const collectItems = (arr, result = []) => {
  arr.forEach(prop => {
    result.push({ ...prop });
    collectItems(prop.children || [], result);
  });
  return result;
};

const DashboardWrapper = ({ children, routeKey }) => {
  const { pathname } = useLocation();
  const { applicantNumber } = useParams();
  const navigate = useNavigate();
  const role = useSelector(getUserRole);
  const applicationType = useSelector(getApplicationType);
  const user = useSelector(getUser);
  const application = useSelector(getFullApplicationData);
  const applicantsData = useSelector(getApplicantsData);
  const loading = useSelector(state => getApplicationLoading(state));
  const isStored = useSelector(state => getApplicationDataIsStored(state));
  const appStatusData = useSelector(getApplicationStatusData);
  const [applicationControls] = useGetApplicationControls({ role });
  const [applicationStatuses] = useGetInfosByType('applicationStatus');
  const [storeApplicationData] = useStoreApplicationById();
  const docsAreValid = useSelector(getDocsAreValid);
  const [, updateAppLoading, updateAppCall] = useApiCall(updateApplicationApi);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedAppId, setSelectedAppId] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const items = role ? convertRoutesToItems(protectedRouts?.[role], applicationControls) : [];
  const selectedItem = collectItems(items)?.find(el => el?.key === pathname);
  const isSubmitted = [
    'taken',
    'approved',
    'fundReady',
    'mortgageFunded',
    'rejected',
    'cancelled',
    'docRejected',
  ].includes(appStatusData?.code);

  const wrapChildren = React.Children.only(
    React.cloneElement(children, {
      isAdmin: isSubmitted,
    })
  );

  const dashboardWrapperProps = {
    getQuote: { backColor: '#fff', hideSideBar: true, padding: '0' },
    wpQuote: { backColor: '#fff', hideSideBar: true, padding: '0' },
    newMortgage: { hideSideBar: true, padding: '50', customSidebar: true },
    mortgageRenewal: { hideSideBar: true, padding: '50', customSidebar: true },
    refinanceMortgage: { hideSideBar: true, padding: '50', customSidebar: true },
    applications: { hideSideBar: !savedAppId(), customSidebar: true },
    myProfile: { hideSteps: true },
  };

  useEffect(() => {
    setOpenKeys(prevState => [...prevState, ...(selectedItem?.parentkeys || [])]);
  }, [pathname, application]);

  useEffect(() => {
    if (user && user?.role?.type === 'customer') {
      storeApplicationData(user?.id, savedAppId());
    }
  }, [user]);

  useEffect(() => {
    setSelectedAppId(application?.originApplication?.id);
  }, [application?.originApplication]);

  useEffect(() => {
    if (
      user?.role?.type === 'customer' &&
      savedAppId() &&
      application?.originApplication?.id &&
      application?.originApplication?.id === selectedAppId
    ) {
      updateApplicationApi({
        id: savedAppId(),
        values: { data: { data: application?.applicationData } },
      });
    }
  }, [application?.applicationData, application?.originApplication]);

  const checkSubmitActive = useMemo(() => {
    const typeData = {
      newMortgage: isNewMortgageCompleted(applicationControls),
      refinanceMortgage: isRefinanceCompleted(applicationControls),
      mortgageRenewal: isRenewalCompleted(applicationControls),
    };
    return (
      typeData[applicationType] &&
      docsAreValid &&
      appStatusData?.code === 'created' &&
      application?.originApplication?.clientConsent
    );
  }, [application, docsAreValid]);

  const submitApplication = () => {
    updateAppCall(
      {
        id: savedAppId(),
        values: {
          data: {
            applicationStatusId: applicationStatuses?.find(el => el.code === 'taken')?.id,
          },
        },
      },
      () => {
        storeApplicationData(user?.id, savedAppId(), () => {
          setSuccessModalOpen(true);
        });
      }
    );
  };

  if (
    loading ||
    (![
      '/',
      '/new-mortgage',
      '/mortgage-renewal',
      '/refinance-mortgage',
      '/wp-quote',
      '/my-profile',
    ].includes(pathname) &&
      !isStored &&
      user?.role?.type === 'customer')
  )
    return <PageSpinner />;

  if (+(applicantNumber || 0) > applicantsData?.length) {
    return <Navigate to='/' replace />;
  }

  if (selectedItem?.disabled) return <Navigate to='/' />;

  const { backColor, hideSideBar, padding, hideSteps, customSidebar } =
    dashboardWrapperProps[routeKey] || {};

  return (
    <>
      {successModalOpen && <SubmitSuccessPopup closeModal={() => setSuccessModalOpen(false)} />}
      <Layout>
        <DashboardHeader
          role={role}
          hideSideBar={hideSideBar}
          hideSteps={hideSteps}
          openKeys={openKeys}
          setOpenKeys={setOpenKeys}
          isSubmitted={isSubmitted}
          customSidebar={customSidebar}
          successModalOpen={successModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
        />
        <Layout>
          {!hideSideBar && (
            <Sider
              trigger={null}
              collapsible
              theme='light'
              width={300}
              min_width='300px'
              collapsedWidth={56}
              shadow='0px 10px 15px rgba(0, 0, 0, 0.2)'
            >
              {!hideSteps ? (
                <Col>
                  <Menu
                    openKeys={openKeys || []}
                    onOpenChange={setOpenKeys}
                    mode='inline'
                    selectedKeys={pathname}
                    type='sidebar'
                    items={items}
                    onClick={item => {
                      if (!submenuKeys.includes(item.key)) navigate(item.key);
                    }}
                  ></Menu>
                  {user?.role?.type === 'customer' &&
                    ([
                      'taken',
                      'approved',
                      'fundReady',
                      'mortgageFunded',
                      'rejected',
                      'docRejected',
                    ].includes(appStatusData?.code) ? (
                      <div style={{ padding: '30px', color: '#717579' }}>
                        <Paragraph mb={5} fz={16} fw={600} color={colors.main_color}>
                          {appStatusData?.value}
                        </Paragraph>
                        <ReactMarkdown
                          children={appStatusData?.note || ''}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw]}
                        />
                        {appStatusData?.code === 'docRejected' && (
                          <Button
                            style={{ width: '100%', marginTop: '5px' }}
                            type='primary'
                            onClick={submitApplication}
                            loading={updateAppLoading}
                          >
                            Resubmit
                          </Button>
                        )}
                      </div>
                    ) : (
                      <Button
                        disabled={!checkSubmitActive}
                        style={{ width: '90%', margin: '15px' }}
                        type='primary'
                        onClick={submitApplication}
                        loading={updateAppLoading}
                      >
                        Submit
                      </Button>
                    ))}
                </Col>
              ) : (
                <div style={{ height: '100%' }}></div>
              )}
              {user?.role?.type === 'customer' && (
                <SidebarFooter user={user?.role?.type} isApp={true} />
              )}
            </Sider>
          )}
          {customSidebar && (
            <Sider
              trigger={null}
              collapsible
              theme='light'
              width={300}
              min_width='300px'
              collapsedWidth={56}
              shadow='0px 10px 15px rgba(0, 0, 0, 0.2)'
            >
              <HomeSidebar />
            </Sider>
          )}
          <Layout>
            <Content back_color={backColor} padding={padding}>
              {wrapChildren}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default memo(DashboardWrapper);
