/* eslint-disable import/no-cycle */
import { IconWrapper } from '../../components/atoms';

const Warning = () => (
  <svg width='26' height='22' viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M13 0.714355L1 20.7144H25L13 0.714355Z' stroke='#35C5A4' strokeLinejoin='round' />
    <path d='M13 16.5037V17.03M13 8.08264L13.0043 13.3458' stroke='#35C5A4' strokeLinecap='round' />
  </svg>
);

const WarningIcon = props => <IconWrapper component={Warning} {...props} />;

export default WarningIcon;
