import styled, { css } from 'styled-components';
import { Select as select } from 'antd';
import { colors } from '../../../constants/colors';

const Select = styled(select)`
  width: 100%;
  .ant-select-selector {
    background: #fff !important;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 40px !important;
    border: 1px solid #d9d9d9 !important;
    font-size: 14px;
    color: #000;
    width: 100%;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 20px !important;
    height: fit-content !important;
  }

  & .ant-select-selection-placeholder {
    color: #000;
  }

  & .anticon::after {
    border-top-color: ${colors.border_color} !important;
  }

  & .ant-select-selection-search-input {
    height: 30px !important;
  }

  & .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: ${colors.main_color} !important;
    .ant-select-selector {
      border-color: ${colors.main_color} !important;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${colors.main_color};
    box-shadow: 0 0 0 2px ${colors.main_color}2e;
    border-right-width: 1px;
    outline: 0;
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }

  & .ant-select-selector {
    text-align: start;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #00000096;
  }

  & .ant-select-suffix svg {
    display: none;
  }

  & .ant-select-suffix {
    position: unset;
  }

  & .ant-select-arrow > span:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #5f6982;
  }

  ${props =>
    props.type === 'blue' &&
    css`
      .ant-select-selector {
        border: 1px solid #000080;
      }
      .ant-select-arrow {
        color: #000080;
      }
    `}
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selector {
        padding: 0 5px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      .ant-select-selector {
        padding: ${props.padding};
      }
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
  ${props =>
    props.height &&
    css`
      .ant-select-selector {
        height: ${props.height};
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selection-item {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.back_color &&
    css`
      .ant-select-selector {
        background-color: ${props.back_color};
      }
    `}
   ${props =>
    props.clear_right &&
    css`
      & .ant-select-clear {
        right: ${props.clear_right} !important;
      }
    `}
`;

const CustomSelect = ({ children, ...props }) => (
  <Select virtual={false} {...props}>
    {children}
  </Select>
);

CustomSelect.Option = Select.Option;

export default CustomSelect;
