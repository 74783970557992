/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applicationData: {
    'applicants-property': {
      applicants: [
        {
          identification: {},
          'registered-address': [],
          income: {},
          // 'other-income': {},
          'owned-properties': {},
          // 'banking-details': {},
        },
      ],
      'target-property': {},
      'renewing-property': {},
      'down-payment': [],
    },
    // 'application-review': {},
    'mortgage-builder': {},
    documents: {},
  },
  originApplication: {},
  applicationLoading: false,
  applicationDataIsStored: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    storeOriginApplication(state, { payload }) {
      state.originApplication = payload;
      state.applicationData = payload?.data;
    },
    setApplicationData(state, { payload }) {
      if (payload?.section) {
        state.applicationData[payload?.mainSection][payload?.section] = payload.values;
      } else {
        state.applicationData[payload?.mainSection] = payload.values;
      }
    },
    setOriginData(state, { payload }) {
      if (payload?.section) {
        state.originApplication[payload?.key] = payload.value;
      }
    },
    setApplicantData(state, { payload }) {
      state.applicationData['applicants-property'].applicants[payload.index][payload.section] =
        payload.values;
    },
    setApplicants(state, { payload }) {
      state.applicationData['applicants-property'].applicants = payload;
    },
    setApplicationLoading(state, { payload }) {
      state.applicationLoading = payload;
    },
    setApplicationDataIsStored(state, { payload }) {
      state.applicationDataIsStored = payload;
    },
    clearApplicationData(state, { payload }) {
      state.originApplication = initialState.originApplication;
      state.applicationData = initialState.applicationData;
      localStorage.removeItem('applicationId');
    },
    addNewApplicantData(state) {
      state.applicationData['applicants-property'].applicants = [
        ...state.applicationData['applicants-property'].applicants,
        initialState.applicationData['applicants-property'].applicants[0],
      ];
    },
  },
});

export const {
  setApplicationData,
  storeOriginApplication,
  setApplicantData,
  setApplicationLoading,
  setApplicationDataIsStored,
  clearApplicationData,
  addNewApplicantData,
  setApplicants,
  setOriginData,
} = applicationSlice.actions;
export default applicationSlice.reducer;
