import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/global.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1ab1e2cd763b4e85b9d181079e20fd4e@o4504283525021696.ingest.sentry.io/4504283530002432',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
