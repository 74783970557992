import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Paragraph, Col, Row } from 'components/atoms/index';
import Chat from '../../assets/images/chat.png';

const SidebarFooter = ({ user, width = 'fit-content', isApp }) => {
  if (isApp) {
    return (
      <div className='footer-content'>
        <div className='chat_wrapper selected_app'>
          <img src={Chat} alt='logo' />
          <div className='clickable_content'></div>
        </div>
      </div>
    );
  }

  return (
    <Row
      gutter={[0, 20]}
      padding='30px 29px 20px'
      border='1px solid #35C5A4'
      radius='20px'
      margin='0 20px 20px 20px'
      width={width}
      direction='column'
      className='contacts-wrapper'
    >
      <Col>
        <Paragraph fz={18} mb={0}>
          Get in touch with us
        </Paragraph>
      </Col>
      <Col align='start'>
        <PhoneFilled style={{ color: '#C2DCFF', fontSize: 18 }} />
        <a href='tel:111-222-4444'>
          <Paragraph mb={0} fw={400} fz={14} ml={15}>
            (111) 222 4444
          </Paragraph>
        </a>
      </Col>
      <Col align='start'>
        <MailFilled style={{ color: '#C2DCFF', fontSize: 18 }} />
        <a href='mailto:info@ratebuzz.ca'>
          <Paragraph mb={0} fw={400} fz={14} ml={15}>
            info@ratebuzz.ca
          </Paragraph>
        </a>
      </Col>
    </Row>
  );
};

export default SidebarFooter;
