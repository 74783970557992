import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { protectedRouts } from 'constants/routes';
import { getUser } from './app/services/selector-helpers';
import DashboardWrapper from './components/templates/dashboard-wrapper';
import GuestWrapper from './components/templates/guest-wrapper';

export const lazyLoadPage = path => lazy(() => import(`./${path}`));

const PageContainer = ({ route, fallback }) => {
  const user = useSelector(getUser);
  const Children = lazyLoadPage(route.component);

  if (user?.id) {
    return (
      <Suspense fallback={fallback}>
        <DashboardWrapper routeKey={route?.key}>
          <Children />
        </DashboardWrapper>
      </Suspense>
    );
  }

  return (
    <Suspense>
      <GuestWrapper routeKey={route?.key}>
        <Children />
      </GuestWrapper>
    </Suspense>
  );
};

export default PageContainer;

const convertToOneLevel = (routes = {}) => {
  const value = Object.entries(routes);
  return value.reduce((prev, cur) => {
    if ('items' in cur[1]) {
      return {
        ...prev,
        ...(convertToOneLevel(cur[1]?.items) || {}),
      };
    }
    return {
      ...prev,
      [cur?.[0]]: {
        component: cur?.[1]?.component,
        pathname: cur?.[1]?.pathname,
        redirectPath: cur?.[1]?.redirectPath,
        pageTitle: cur?.[1]?.pageTitle,
        key: cur?.[1]?.key,
      },
    };
  }, {});
};

export const getPageComponents = role => {
  return convertToOneLevel(protectedRouts[role]);
};
