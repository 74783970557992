/* eslint-disable no-return-assign */

import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined } from '@ant-design/icons';
import { showConfirm } from 'utils/helpers';
import { setApplicants } from 'app/slices/application';
import { useNavigate } from 'react-router-dom';
import {
  getApplicationData,
  getApplicationOriginData,
  getApplicationStatusData,
  getApplicationType,
  getDocsAreValid,
} from '../../app/services/selector-helpers';
import {
  calculateData,
  downPaymentSetting,
} from '../../components/organisms/mortgage/new-mortgage/down-payment';
import provider from '../../services/main-client';
import { documentsEndpoint } from '../../constants/endpoints';
import useApiCall from './useApiCall';

const applicantsSections = [
  'identification',
  'registeredAddress',
  'income',
  // 'otherIncome',
  'ownedProperties',
  // 'bankingDetails',
];

export const checkDownPaymentCompleted = (applicants, downData, purchasePrice) => {
  const propAmount = (applicants || [])
    ?.map(el => (el?.['owned-properties']?.length ? el?.['owned-properties'] : []))
    ?.flatMap(val => val)
    ?.filter(el => el?.purposeTransaction === 'sold')
    ?.reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += curr?.downPaymentProperty || 0;
      return acc;
    }, 0);

  const amount =
    (downData || [])?.reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += curr?.downPaymentAmount || 0;
      return acc;
    }, 0) + propAmount;
  const { isValid } = calculateData(downPaymentSetting, amount, purchasePrice);
  return isValid;
};

const checkMainItemsDisabled = (applicants = [], controls) => {
  const applicantsCompletedArr = applicants?.map((e, index) =>
    applicantsSections?.map(key => !!controls?.[key]?.completed(index))?.includes(false)
  );
  return applicantsCompletedArr.includes(true);
};

const useGetApplicationControls = ({ role }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applicationData = useSelector(getApplicationData);
  const originData = useSelector(getApplicationOriginData);
  const applicationType = useSelector(getApplicationType);
  const docsAreValid = useSelector(getDocsAreValid);
  const appStatusData = useSelector(getApplicationStatusData);
  const [updateApplicationDocuments] = provider(documentsEndpoint, ['add']);
  const [, , updateApplicationDocumentsCall] = useApiCall(updateApplicationDocuments);
  const purchasePrice = originData?.purchasePrice;

  const isSubmitted = [
    'taken',
    'approved',
    'fundReady',
    'mortgageFunded',
    'rejected',
    'cancelled',
    'docRejected',
  ].includes(appStatusData?.code);

  const deleteCoApplicant = (e, index) => {
    e.stopPropagation();
    showConfirm({
      title: 'Are you sure you want to remove your co-applicant?',
      onOk: () => {
        updateApplicationDocumentsCall(
          {
            data: {
              deleteApplicantIndex: index,
              applicationId: originData?.id,
            },
          },
          () => {
            dispatch(
              setApplicants(
                applicationData?.['applicants-property']?.applicants.filter(
                  (el, idx) => idx !== index
                )
              )
            );
            // setTimeout(() => {
            // storeApplicationData(user?.id, savedAppId(), () => {}, true, true);
            navigate('/identification/0');
            // }, 500);
          }
        );
      },
    });
  };

  const controls = {
    customer: {
      applicants: {
        multiplyData: applicationData?.['applicants-property']?.applicants.map((el, index) => ({
          ...el,
          customLabel:
            el?.identification?.firstName || el?.identification?.lastName
              ? `${el?.identification?.firstName || ''} ${el?.identification?.lastName || ''}`
              : '',
          icon:
            index > 0 && !isSubmitted ? (
              <MinusCircleOutlined onClick={e => deleteCoApplicant(e, index)} />
            ) : (
              ''
            ),
        })),
      },
      identification: {
        completed: index =>
          applicationData?.['applicants-property']?.applicants?.[index]?.identification
            ?.maritalStatus,
      },
      registeredAddress: {
        completed: index => {
          return (
            applicationData?.['applicants-property']?.applicants?.[index]?.[
              'registered-address'
            ]?.reduce((acc, curr) => {
              // eslint-disable-next-line no-param-reassign
              acc += curr?.livingDuration || 0;
              return acc;
            }, 0) >= 36
          );
        },
        disabled(index) {
          return !controls.identification?.completed(index);
        },
      },
      income: {
        completed: index => {
          return applicationData?.['applicants-property']?.applicants?.[index]?.income?.length
            ? applicationData?.['applicants-property']?.applicants?.[index]?.income?.reduce(
                (acc, curr) => {
                  return acc + (curr?.durationInMonths || 0);
                },
                0
              ) >= 36
            : false;
        },
        disabled: index => {
          return !controls.registeredAddress?.completed(index);
        },
      },

      // otherIncome: {
      //   completed: index =>
      //     applicationData?.['applicants-property']?.applicants?.[index]?.['other-income']
      //       ?.noIncome ||
      //     applicationData?.['applicants-property']?.applicants?.[index]?.['other-income']?.length,
      //   disabled(index) {
      //     return !controls.income?.completed(index);
      //   },
      // },
      ownedProperties: {
        completed: index =>
          applicationData?.['applicants-property']?.applicants?.[index]?.['owned-properties']
            ?.noProperty ||
          applicationData?.['applicants-property']?.applicants?.[index]?.['owned-properties']
            ?.length,
        disabled(index) {
          return !controls.income?.completed(index);
        },
      },
      // bankingDetails: {
      //   completed: index =>
      //     !!applicationData?.['applicants-property']?.applicants?.[index]?.['banking-details']
      //       ?.institution,
      //   disabled(index) {
      //     return !controls.ownedProperties?.completed(index);
      //   },
      // },
      targetProperty: {
        level: 1,
        hidden: applicationType !== 'newMortgage',
        completed: () =>
          !!applicationData?.['applicants-property']?.['target-property']?.purchasePrice,
        disabled() {
          return checkMainItemsDisabled(
            applicationData?.['applicants-property']?.applicants,
            controls
          );
        },
      },
      refinancingProperty: {
        level: 1,
        hidden: applicationType !== 'refinanceMortgage',
        completed: () =>
          !!applicationData?.['applicants-property']?.['refinancing-property']?.refinancingProperty
            ?.purchasePrice,
        disabled() {
          return checkMainItemsDisabled(
            applicationData?.['applicants-property']?.applicants,
            controls
          );
        },
      },
      renewingProperty: {
        level: 1,
        hidden: applicationType !== 'mortgageRenewal',
        completed: () => {
          const mainItem = applicationData?.['applicants-property']?.['renewal-property'];
          return (
            !!(mainItem?.purchasePrice && mainItem?.propertyValue) ||
            !!(
              mainItem?.renewingProperty?.purchasePrice && mainItem?.renewingProperty?.propertyValue
            )
          );
        },
        disabled() {
          return checkMainItemsDisabled(
            applicationData?.['applicants-property']?.applicants,
            controls
          );
        },
      },
      downPayment: {
        level: 1,
        hidden: applicationType !== 'newMortgage',
        completed: () =>
          checkDownPaymentCompleted(
            applicationData?.['applicants-property']?.applicants,
            applicationData?.['applicants-property']?.['down-payment'],
            purchasePrice
          ),
        disabled: () => {
          return !controls.targetProperty?.completed();
        },
      },
      documents: {
        level: 1,
        completed: () => {
          return docsAreValid;
        },
        disabled: () => {
          const types = {
            newMortgage: 'downPayment',
            mortgageRenewal: 'renewingProperty',
            refinanceMortgage: 'refinancingProperty',
          };
          return !controls?.[types[applicationType]]?.completed();
        },
      },
    },
  }[role];

  return [applicationData ? controls || {} : {}];
};

export default useGetApplicationControls;
