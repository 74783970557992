import { Col } from 'antd';
import styled, { css } from 'styled-components';

const MainWrapper = styled(Col)`
  padding: 0 10%;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  @media screen and (max-width: 576px) {
    padding: 0;
    font-size: 16px;
    ${props =>
      props.margin_mobile &&
      css`
        margin: ${props.margin_mobile};
      `}
  }
`;

const MortgageQuestionContentWrapper = ({ children, style, margin, marginMobile, padding }) => {
  return (
    <MainWrapper style={style} margin={margin} margin_mobile={marginMobile} padding={padding}>
      {children}
    </MainWrapper>
  );
};

export default MortgageQuestionContentWrapper;
