/* eslint-disable import/no-cycle */
import styled, { css } from 'styled-components';
import { Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useGetAppSettingsByCode from 'utils/hooks/useGetAppSettingsByCode';
import { colors } from 'constants/colors';
import { Tooltip } from '../index';

const FormItem = styled(Form.Item)`
  max-width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 0;
  margin-bottom: 10px;
  position: relative;

  & .ant-form-item-label {
    padding: 0;
    max-height: 11px;
    position: absolute;
    top: -4px;
    left: 15px;
    z-index: 2;
    font-size: 8px;
    color: #54595f;
    height: 10px;
    display: flex;
    overflow: initial;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 1px;
      background: white;
    }
    > label {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 10px;
      color: #54595f;
      height: 100%;

      &:before {
        margin-right: 2px !important;
        color: #54595f !important;
        font-size: 9px !important;
      }

      &:after {
        display: none;
      }
    }
  }

  .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-form-item-explain {
    font-size: 12px;
    text-align: left;
  }

  ${props =>
    (props.label_mb || props.label_mb === 0) &&
    css`
      .ant-form-item-label > label {
        margin-bottom: ${props.label_mb}px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.margin_right &&
    css`
      margin-right: ${props.margin_right};
    `}
  ${props =>
    props.justify &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.justify};
      }
    `}

    ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.layout === 'vertical' &&
    css`
      div {
        width: 100%;
        text-align: start;
      }
    `}
  ${props =>
    props.labelcolor &&
    css`
      .ant-form-item-label > label {
        color: ${props.labelcolor};
      }
    `}
  ${props =>
    props.labelalign &&
    css`
      .ant-form-item-label {
        text-align: ${props.labelalign};
      }
    `}
  ${props =>
    props.content_just &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.content_just};
      }
    `}
  ${props =>
    props.labelheight &&
    css`
      .ant-form-item-label {
        height: ${props.labelheight};
      }
    `}

  ${props =>
    props.hide === 'requiredText' &&
    css`
      .ant-form-item-explain {
        display: none;
      }
    `}
  ${props =>
    props.error_padding &&
    css`
      .ant-form-item-explain {
        padding: ${props.error_padding};
      }
    `}
    ${props =>
    props.error_fz &&
    css`
      .ant-form-item-explain {
        font-size: ${props.error_fz};
      }
    `}
  ${props =>
    props.display_item === 'none' &&
    css`
      .ant-form-item-control-input {
        display: ${props.display_item};
      }
    `}
  ${props =>
    props.show_only === 'validate' &&
    css`
      .ant-form-item-control-input,
      .ant-form-item-label {
        display: none !important;
      }
      ~ .field_info {
        display: none;
      }
    `}
   ${props =>
    props.with_title &&
    css`
      & {
        max-width: calc(100% - 40px) !important;
      }
      & .ant-select .ant-select-selector,
      & .ant-input,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & .ant-input-number-affix-wrapper,
      & .ant-input-number,
      & .ant-input-number-group-wrapper,
      & :is(.ant-input, #A#A#A:not(*)) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      & .ant-select .ant-select-selector,
      & .ant-picker,
      & .ant-input-affix-wrapper,
      & .ant-input-number-affix-wrapper,
      & .ant-input-number-group-wrapper {
        height: 48px !important;
      }
      & .ant-input-password input {
        height: auto !important;
      }
      & .ant-radio-group {
        width: calc(100% + 40px);
        margin-right: 40px;
      }
    `}
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  right: -5px;
  height: 48px;
  & span {
    height: 100%;
    background-color: transparent !important;
    border: 1px solid ${colors.border_color};
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
    border-left: none !important;
    padding: 0 4px 0 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
  ${props =>
    props.border === 'none' &&
    css`
      height: 65%;
      & span {
        border: none;
      }
    `}
`;

FormItem.defaultProps = {};

const addClass = (e, spanId, value, formId) => {
  window.requestAnimationFrame(() => {
    const formItem = document.getElementById(formId);
    const span = document.getElementById(spanId);

    if (
      (value && span?.className) ||
      (formItem?.getElementsByClassName?.('ant-select-selection-item')?.length && span?.className)
    ) {
      span.className = 'floating-label floating-label-active';
    } else if (
      (![null, undefined, ''].includes(e?.target?.defaultValue) ||
        ![null, undefined, ''].includes(e?.target?.value)) &&
      span?.className
    ) {
      span.className = 'floating-label floating-label-active';
    } else if (span?.className) {
      span.className = 'floating-label';
    }
  });
};
const isFilled = spanId => {
  const span = document.getElementById(spanId);
  if (span?.className) {
    window.requestAnimationFrame(() => {
      span.className = 'floating-label floating-label-active';
    });
  }
};

const checkForFill = (e, spanId, value, formId) => {
  window.requestAnimationFrame(() => {
    const formItem = document.getElementById(formId);
    const span = document.getElementById(spanId);

    if (
      (value && span?.className) ||
      (formItem?.getElementsByClassName?.('ant-select-selection-item')?.length &&
        span?.className) ||
      ((![null, undefined, ''].includes(e?.target?.defaultValue) ||
        ![null, undefined, ''].includes(e?.target?.value)) &&
        span?.className)
    ) {
      span.className = 'floating-label floating-label-active';
    }
  });
};
const FormItemComponent = ({
  getValueProps,
  onFocus,
  onBlur,
  label,
  children,
  noInfoBorder,
  hideInfo,
  ...props
}) => {
  const [data = ''] = useGetAppSettingsByCode('helper');
  const filteredName = Array.isArray(props?.name)
    ? props?.name?.filter(el => typeof el !== 'number')
    : props?.name;
  const title =
    !hideInfo &&
    (data?.jsonValue || {})?.[Array.isArray(filteredName) ? filteredName.join('.') : filteredName];

  const dynamicFieldName = `${
    Array.isArray(props?.name) ? props.name.join('_') : props?.name || label
  }_${Math.random()}`;

  return (
    <div style={{ position: 'relative' }} id={`${dynamicFieldName}_formItem`}>
      <FormItem
        error_padding='0 0 0 25px'
        error_fz='10px'
        with_title={title || ''}
        {...(props?.valuePropName !== 'checked' && {
          getValueProps: value => {
            label &&
              checkForFill('', `${dynamicFieldName}_span`, value, `${dynamicFieldName}_formItem`);
            getValueProps?.(value);
            return { value };
          },
        })}
        onFocus={e => {
          label && isFilled(`${dynamicFieldName}_span`);
          onFocus?.(e);
        }}
        onBlur={e => {
          label && addClass(e, `${dynamicFieldName}_span`, '', `${dynamicFieldName}_formItem`);
          onBlur?.(e);
        }}
        {...props}
      >
        {children}
      </FormItem>
      {label && (
        <span className='floating-label' id={`${dynamicFieldName}_span`}>
          <span>{label}</span>
        </span>
      )}
      {title && (
        <TooltipWrapper border={noInfoBorder ? 'none' : ''} className='field_info'>
          <Tooltip title={title} overlayClassName='info_tooltip' color={colors.main_color}>
            <InfoCircleOutlined style={{ color: '#00000073' }} />
          </Tooltip>
        </TooltipWrapper>
      )}
    </div>
  );
};

export default FormItemComponent;
