/* eslint-disable import/no-cycle */
import { Col, Card } from 'components/atoms';

const QuoteWrapper = ({ children, style, isAdmin, bodyPadding }) => {
  return (
    <Col margin='auto' lg={24} xl={13} style={style}>
      <Card
        shadow='0px 4px 25px rgba(0, 0, 0, 0.15)'
        radius='20px'
        bpadding={bodyPadding}
        {...(isAdmin ? { shadow: 'none', border: 'none' } : {})}
      >
        {children}
      </Card>
    </Col>
  );
};

export default QuoteWrapper;
