import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../assets/custom-icons/CloseIcon';
import { Button, Col, ModalWrapper, Paragraph, Row } from '../atoms';
import ModalContent from './modal-content';

const PopupWrapper = styled(Col)`
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 20px;
  margin: 0 20px;

  .close_icon {
    position: absolute;
    top: 35px;
    right: 20px;
    cursor: pointer;
  }

  .title {
    padding-right: 30px;
    text-align: left;
  }

  @media (max-width: 576px) {
    padding: 25px 10px;

    .close_icon {
      top: 25px;
      right: 10px;
    }

    .title {
      font-size: 16px;
    }

    .content {
      font-size: 14px;
    }
  }
`;

const SubmitSuccessPopup = ({ closeModal }) => {
  return (
    <ModalWrapper closeModal={closeModal}>
      <ModalContent customMaxWidth='578px' closeModal={closeModal} hideClose={false}>
        <PopupWrapper>
          <Row justify='space-between' align='middle' mb={20}>
            <Paragraph fz={24} fw='500' mb={0} className='title'>
              Thank you for your application!
            </Paragraph>
            <Col padding='0' className='close_icon'>
              <CloseIcon onClick={closeModal} />
            </Col>
          </Row>
          <Paragraph fz={16} className='content'>
            Your application has been sent to the Ratebuzz team for review, and one of our advisors
            will be reaching out to you shortly to confirm all of the details before submitting it
            to the mortgage lender.
          </Paragraph>
          <Col span={24} justify='end'>
            <Button type='primary' onClick={closeModal} padding='13px 50px' margin='0 0 0 auto'>
              Ok
            </Button>
          </Col>
        </PopupWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};

export default SubmitSuccessPopup;
