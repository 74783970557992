import { IconWrapper } from '../../components/atoms';

const Dollar = () => (
  <svg width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.86 16.566H5.03V15.09C7.46 14.874 8.792 13.182 8.792 11.436C8.792 8.898 6.83 8.412 5.03 7.98V4.092C5.894 4.308 6.398 4.974 6.47 5.694H8.666C8.504 3.66 7.1 2.526 5.03 2.346V0.887999H3.86V2.364C1.628 2.562 0.08 3.948 0.08 5.964C0.08 8.502 2.06 9.006 3.86 9.456V13.416C2.852 13.236 2.348 12.57 2.276 11.67H0.08C0.08 13.704 1.664 14.91 3.86 15.108V16.566ZM6.722 11.544C6.722 12.48 6.11 13.236 5.03 13.416V9.762C6.002 10.068 6.722 10.518 6.722 11.544ZM2.168 5.856C2.168 4.758 2.87 4.164 3.86 4.038V7.674C2.888 7.368 2.168 6.9 2.168 5.856Z'
      fill='black'
    />
  </svg>
);

const DollarIcon = props => <IconWrapper component={Dollar} {...props} />;

export default DollarIcon;
