import { createCustomApi } from '../../configs/rtk-query';

export const infoTypesApi = createCustomApi({
  reducerPath: 'infoTypes',
  endpoints: builder => ({
    getInfoTypes: builder.query({
      query: () => ({
        url: '/types?pagination[limit]=200',
        params: { active: true, sort: 'seq' },
      }),
    }),
  }),
});

export const { useGetInfoTypesQuery } = infoTypesApi;
