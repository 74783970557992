/* eslint-disable import/no-cycle */
import { forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { Input } from '../index';

// const MaskedInput = styled(ReactInputMask)`
//   border: 1px solid #d9d9d9;
//   border-radius: 40px;
//   height: 48px;
//   width: 100%;
//   padding-left: 30px;
//   color: #000;
//   &:hover {
//     border: 1px solid ${colors.main_color};
//   }
//   &:focus {
//     outline: none;
//     border: 1px solid ${colors.main_color};
//     box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
//   }
//   &::placeholder {
//     color: #000;
//   }
//   &[disabled] {
//     color: #eeeeee;
//     background-color: #fff;
//     border: 1px solid #eeeeee;
//   }
//   ${props =>
//     (props.mb || props.mb === 0) &&
//     css`
//       margin-bottom: ${props.mb}px!important;
//     `}
//   ${props =>
//     props.margin &&
//     css`
//       margin: ${props.margin};
//     `}
//   ${props =>
//     props.padding &&
//     css`
//       padding: ${props.padding}!important;
//     `}
//   ${props =>
//     props.height &&
//     css`
//       height: ${props.height}!important;
//     `}
//   ${props =>
//     props.cursor &&
//     css`
//       cursor: ${props.cursor};
//     `}
//   ${props =>
//     props.radius &&
//     css`
//       border-radius: ${props.radius};
//     `}
//   ${props =>
//     props.type === 'blue' &&
//     css`
//       border: 1px solid #c9c9c9 !important;
//       &:hover {
//         border-color: ${colors.main_color} !important;
//       }
//       &:focus,
//       &.ant-input-focused {
//         border-color: ${colors.main_color} !important;
//         box-shadow: none;
//       }
//     `}
// `;

const InputMask = forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props}>
      {inputProps => (
        <Input
          {...inputProps}
          ref={ref}
          disabled={props.disabled ? props.disabled : null}
          type='tel'
        />
      )}
    </ReactInputMask>
  );
});

export default InputMask;
