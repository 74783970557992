import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { usersApi } from './services/users';
import { authApi } from './services/auth';
import { infoTypesApi } from './services/info-types';
import { settingsApi } from './services/settings';
import user from './slices/user';
import mortgage from './slices/mortgage';
import application from './slices/application';
import documents from './slices/documents';

export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [infoTypesApi.reducerPath]: infoTypesApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    user,
    mortgage,
    application,
    documents,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      usersApi.middleware,
      authApi.middleware,
      infoTypesApi.middleware,
      settingsApi.middleware,
    ]),
});

setupListeners(store.dispatch);
