export const getUserRole = state => state?.user?.user?.role?.type;
export const getUser = state => state?.user?.user;
export const getMortgageData = (state, type) => state?.mortgage?.mortgageData?.[type];
export const getMortgageStep = state => state?.mortgage?.step;
export const getFullApplicationData = state => state?.application;
export const getApplicationData = state => state?.application?.applicationData;
export const getApplicantsData = state =>
  state?.application?.applicationData?.['applicants-property']?.applicants;
export const getApplicantSectionData = (state, index, section) =>
  state?.application?.applicationData?.['applicants-property']?.applicants?.[index]?.[section];
export const getApplicantPropertySectionData = (state, section) =>
  state?.application?.applicationData?.['applicants-property']?.[section];
export const getApplicationOriginData = state => state?.application?.originApplication;
export const getApplicationLoading = state => state?.application?.applicationLoading;
export const getApplicationDataIsStored = state => state?.application?.applicationDataIsStored;
export const getApplicationType = state =>
  state?.application?.originApplication?.applicationTypeId?.code;
export const getApplicationTypeId = state =>
  state?.application?.originApplication?.applicationTypeId?.id;
export const getDocsAreValid = state => state?.documents?.docsAreValid;

export const getApplicationStatusData = state =>
  state?.application?.originApplication?.applicationStatusId;
