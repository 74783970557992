export const isCompleted = applicationControls => {
  const isIdentification = applicationControls?.applicants?.multiplyData
    ?.map((el, idx) => applicationControls?.identification?.completed(idx))
    .every(value => value !== undefined);

  const isRegisteredAddress = applicationControls?.applicants?.multiplyData
    ?.map((el, idx) => applicationControls?.registeredAddress?.completed(idx))
    .every(value => value !== false);

  const isIncome = applicationControls?.applicants?.multiplyData
    ?.map((el, idx) => applicationControls?.income?.completed(idx))
    .every(value => value !== false);

  // const isOtherIncome = applicationControls?.applicants?.multiplyData
  //   ?.map((el, idx) => applicationControls?.otherIncome?.completed(idx))
  //   .every(value => value !== undefined);

  const isOwnedProperties = applicationControls?.applicants?.multiplyData
    ?.map((el, idx) => applicationControls?.ownedProperties?.completed(idx))
    .every(value => value !== undefined);

  // const isBankingDetails = applicationControls?.applicants?.multiplyData
  //   ?.map((el, idx) => applicationControls?.bankingDetails?.completed(idx))
  //   .every(value => value !== false);

  return (
    isIdentification &&
    isRegisteredAddress &&
    isIncome &&
    // isOtherIncome &&
    isOwnedProperties
    // isBankingDetails
  );
};
