import styled from 'styled-components';
import { Typography } from 'antd';

const { Paragraph: paragraph } = Typography;
const MortgageTitle = styled(paragraph)`
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: space-around;
  color: #000000;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
`;

/** @component */
export default MortgageTitle;
