import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Sider = styled(layout.Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  z-index: 5;
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export default Sider;
