/* eslint-disable no-nested-ternary */
import {
  CheckCircleOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileTextOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { addUploadApi } from 'app/services/upload';
import { Modal, Paragraph } from 'components/atoms';
import moment from 'moment';
import PurchaseImage from '../assets/images/purchase-image.png';
import RenewalImage from '../assets/images/renewal-image.png';
import RefinanceImage from '../assets/images/refinance-image.png';

const { confirm } = Modal;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const defText = (text, align, isPrice, extraProps = {}, emptyValue) => {
  let rowContent = '';
  if (!text) {
    rowContent = emptyValue ? '' : '-';
  } else {
    rowContent = text;
    if (isPrice) {
      rowContent = isPrice?.onlyLocale
        ? Number(text).toLocaleString()
        : Number(text).toLocaleString(undefined, { minimumFractionDigits: 2 });
    }
  }
  return (
    <Paragraph
      mb={0}
      align={align || 'left'}
      fw={400}
      fz={14}
      {...extraProps}
      // text_overflow='ellipsis'
    >
      {rowContent}
    </Paragraph>
  );
};

export const formatPhone = text => text?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const convertFileUrl = url => {
  return url?.includes('https://') ? url : `https://${url}`;
};

export const uploadFile = async (file, setUploadedFile, setUploadLoading) => {
  setUploadLoading(true);
  const callback = await addUploadApi(file);
  setUploadLoading(false);
  if (callback) {
    const uploadedData = callback?.data ? callback?.data[0] : null;
    const uploadedObjFile = { ...uploadedData, url: convertFileUrl(uploadedData?.url) };
    // if you want to collect your images, your default value should be array, otherwise what type do you want
    setUploadedFile(prev => (Array.isArray(prev) ? [...prev, uploadedObjFile] : uploadedObjFile));
  }
  return false;
};

export const showConfirm = (params = {}) => {
  confirm({
    icon: false,
    onCancel() {},
    okText: 'YES',
    cancelText: 'NO',
    destroyOnClose: true,
    maskClosable: true,
    centered: true,
    ...params,
  });
};

export const convertPrice = price =>
  Number(price || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });

export const submenuKeys = ['quote-submenu'];

export const convertRoutesToItems = (routes, controls, listIndex) => {
  const value = Object.entries(routes);
  return value.reduce((prev, cur) => {
    if (cur[1]?.items) {
      return [
        ...prev,
        ...(controls[cur[0]]?.multiplyData?.length
          ? controls[cur[0]]?.multiplyData.map((elem, index) => {
              return {
                icon: elem.icon,
                label:
                  controls[cur[0]]?.completed && controls[cur[0]]?.completed(index) ? (
                    <ItemWrapper>
                      {elem?.customLabel || cur[1].label}
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    </ItemWrapper>
                  ) : (
                    elem?.customLabel || cur[1].label
                  ),
                level: controls[cur[0]]?.level,
                disabled: controls[cur[0]]?.disabled
                  ? controls[cur[0]]?.disabled(index) || false
                  : false,
                key: `${cur[1]?.originPath || cur[1]?.key}_${index}`,
                ...(cur[1]?.parentkeys
                  ? { parentkeys: cur[1]?.parentkeys?.map(el => el?.replace('$index$', index)) }
                  : {}),
                children: [
                  ...(prev?.children || []),
                  ...convertRoutesToItems(cur?.[1]?.items, controls, index.toString()),
                ],
              };
            })
          : !cur[1]?.hideInSidebar && !controls[cur[0]]?.hidden
          ? [
              {
                label:
                  controls[cur[0]]?.completed && controls[cur[0]]?.completed(listIndex) ? (
                    <ItemWrapper>
                      {cur[1].label} <CheckCircleOutlined style={{ color: 'green' }} />
                    </ItemWrapper>
                  ) : (
                    cur[1].label
                  ),
                level: controls[cur[0]]?.level,
                disabled: controls[cur[0]]?.disabled
                  ? controls[cur[0]]?.disabled(listIndex) || false
                  : false,
                key: cur[1]?.originPath || cur[1]?.pathname || cur[1]?.key,
                ...(cur[1]?.parentkeys
                  ? { parentkeys: cur[1]?.parentkeys?.map(el => el?.replace('$index$', listIndex)) }
                  : {}),
                children: [
                  ...(prev?.children || []),
                  ...convertRoutesToItems(cur?.[1]?.items, controls),
                ],
              },
            ]
          : []),
      ];
    }

    return [
      ...prev,
      ...(controls[cur[0]]?.multiplyData?.length
        ? controls[cur[0]]?.multiplyData.map((_, index) => ({
            label:
              controls[cur[0]]?.completed && controls[cur[0]]?.completed(index) ? (
                <ItemWrapper>
                  {cur[1].label}
                  <CheckCircleOutlined style={{ color: 'green' }} />
                </ItemWrapper>
              ) : (
                cur[1].label
              ),
            level: controls[cur[0]]?.level,
            disabled: controls[cur[0]]?.disabled
              ? controls[cur[0]]?.disabled(index) || false
              : false,
            key: `${cur[1]?.originPath || cur[1]?.key}_${index}`,
            ...(cur[1]?.parentkeys
              ? { parentkeys: cur[1]?.parentkeys?.map(el => el?.replace('$index$', index)) }
              : {}),
          }))
        : !cur[1]?.hideInSidebar && !controls[cur[0]]?.hidden
        ? [
            {
              label:
                controls[cur[0]]?.completed && controls[cur[0]]?.completed(listIndex) ? (
                  <ItemWrapper>
                    {cur[1].label}
                    <CheckCircleOutlined style={{ color: 'green' }} />
                  </ItemWrapper>
                ) : (
                  cur[1].label
                ),
              level: controls[cur[0]]?.level,
              disabled: controls[cur[0]]?.disabled
                ? controls[cur[0]]?.disabled(listIndex) || false
                : false,
              key: `${cur[1]?.originPath || cur[1]?.pathname || cur[1]?.key}${
                listIndex ? `/${listIndex}` : ''
              }`,
              ...(cur[1]?.parentkeys
                ? { parentkeys: cur[1]?.parentkeys?.map(el => el?.replace('$index$', listIndex)) }
                : {}),
            },
          ]
        : []),
    ];
  }, []);
};

export const filterOption = (value, option) =>
  option.children.toLowerCase().includes(value.toLowerCase());

export const getPostalCode = async placeId =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!placeId) reject('placeId not provided');

    try {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        details => {
          let postcode = null;
          // eslint-disable-next-line no-unused-expressions
          details?.address_components?.forEach(entry => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name;
            }
          });
          return resolve(postcode);
        }
      );
    } catch (e) {
      reject(e);
    }
  });

export const convertMonthToYears = month => {
  const y = month / 12;
  const mm = month % 12;
  return { year: Math.floor(y), month: mm };
};

export const fileIcons = item => {
  const style = { fontSize: '30px', color: '#5F6982' };
  const typeExt = {
    '.pdf': <FilePdfOutlined style={style} />,
    '.xlsx': <FileExcelOutlined style={style} />,
    '.doc': <FileWordOutlined style={style} />,
    '.docm': <FileWordOutlined style={style} />,
    '.docx': <FileWordOutlined style={style} />,
    '.jpg': <FileImageOutlined style={style} />,
    '.png': <FileImageOutlined style={style} />,
    '.svg': <FileImageOutlined style={style} />,
  };

  // eslint-disable-next-line no-prototype-builtins
  const icon = typeExt?.[item?.ext?.toLowerCase()] || <FileTextOutlined style={style} />;

  return icon;
};
export const convertToObjectIds = (data = {}) =>
  Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value?.id ?? value]));

export const generateDateSetData = (date, key) => {
  if (!date) return { [key]: null };
  const formattedDate = moment(date);
  const day = formattedDate.format('DD');
  const month = formattedDate.format('MM');
  const year = formattedDate.format('YYYY');
  return {
    [`${key}_day`]: day,
    [`${key}_month`]: month,
    [`${key}_year`]: year,
    [key]: formattedDate,
  };
};

export const formatSaveDate = (data, keys) => {
  const dataCopy = { ...data };
  keys.forEach(key => {
    delete dataCopy?.[`${key}_day`];
    delete dataCopy?.[`${key}_month`];
    delete dataCopy?.[`${key}_year`];
    dataCopy[key] = moment(data[key]).format();
  });
  return dataCopy;
};

export const appQuoteContent = {
  newMortgage: { label: 'Purchase', image: PurchaseImage },
  refinanceMortgage: { label: 'Refinance', image: RefinanceImage },
  mortgageRenewal: { label: 'Renewal', image: RenewalImage },
};

export const showLoginValidationWarning = form => {
  form.setFields([
    {
      name: 'identifier',
      value: form.getFieldValue('identifier'),
      errors: [' '],
    },
    {
      name: 'password',
      value: form.getFieldValue('password'),
      errors: ['Email or password is invalid!'],
    },
  ]);
};
