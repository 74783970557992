import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Paragraph, Row } from 'components/atoms/index';
import { appQuoteContent } from 'utils/helpers';
import useGetInfosByType from 'utils/hooks/useGetInfosByType';
import Chat from 'assets/images/chat.png';
import Link from 'components/atoms/Link/index';
import { colors } from 'constants/colors';
import SidebarFooter from './sidebar-footer';

const typeKeys = {
  '/new-mortgage': 'newMortgage',
  '/mortgage-renewal': 'mortgageRenewal',
  '/refinance-mortgage': 'refinanceMortgage',
};
const typeLinks = {
  newMortgage: '/new-mortgage',
  mortgageRenewal: '/mortgage-renewal',
  refinanceMortgage: '/refinance-mortgage',
};

const MortgageWrapper = styled(Row)`
  padding: 40px 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .app_type_content {
    position: sticky;
    top: 20px;

    .image-title {
      display: flex;
      .img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
  }

  //.footer-content {
  //  .contacts-wrapper {
  //    width: 100%;
  //    margin: 0 0 20px 0 !important;
  //    max-width: 260px;
  //    position: fixed;
  //    bottom: 210px;
  //  }
  //}

  @media screen and (max-width: 992px) {
    justify-content: flex-start;
    height: fit-content;
    padding: 15px 15px 0;

    .app_type_content {
      position: relative;
    }
  }
`;

const NewApplicationWrapper = styled.div`
  margin: 0 20px;
  position: sticky;
  top: 0; /* bottom: 20px; */ /* width: 260px; */
  .card-wrapper {
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    border: 2px solid transparent;
    transition: 0.5s;
    .front_content {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
    &:hover {
      border: 2px solid ${colors.main_color};
    }
  }

  @media screen and (max-width: 992px) {
    position: relative;
  }
`;

const HomeSidebar = ({ setCollapsed = () => {} }) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const [applicationTypes] = useGetInfosByType('applicationType');
  const appType = applicationTypes?.find(el => el.code === typeKeys[pathname]);

  return pathname !== '/' ? (
    <MortgageWrapper direction='column' justify='space-between'>
      <div className='app_type_content'>
        <div className='image-title'>
          <img
            src={appQuoteContent[appType?.code]?.image}
            alt={appQuoteContent[appType?.code]?.label}
            className='img'
          />
          <Paragraph fz={24} fw={500} mb='0'>
            {appQuoteContent[appType?.code]?.label}
          </Paragraph>
        </div>
        <Paragraph fz='18' mt='20px' mb={40}>
          {appType?.note}
        </Paragraph>
      </div>
      <div className='footer-content new-app'>
        <SidebarFooter user='admin' width='260px' />
        <div className='chat_wrapper'>
          <img src={Chat} alt='logo' />
          <div className='clickable_content'></div>
        </div>
      </div>
    </MortgageWrapper>
  ) : (
    <NewApplicationWrapper>
      <Paragraph fz={18} fw={500} mb={0} mt='20px' ml={5}>
        Add New Application
      </Paragraph>
      {applicationTypes.map(el => (
        <Col key={el.id} className='card-wrapper'>
          <Link to={typeLinks[el.code]} className='app-content' onClick={() => setCollapsed(false)}>
            <div className='front_content'>
              <img src={appQuoteContent[el?.code]?.image} alt={appQuoteContent[el?.code]?.label} />
              <Paragraph fz={18} fw={500} align='center' mb={0}>
                {appQuoteContent[el?.code]?.label}
              </Paragraph>
            </div>
          </Link>
        </Col>
      ))}

      <div className='footer-content'>
        <div className='chat_wrapper'>
          <img src={Chat} alt='logo' />
          <div className='clickable_content'></div>
        </div>
      </div>
    </NewApplicationWrapper>
  );
};

export default memo(HomeSidebar);
