import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Content, Layout, Sider, Col, Header } from 'components/atoms/index';
import HomeSidebar from 'components/molecules/home-sidebar';
import Logo from 'assets/images/logo.png';
import { WP_ROOT } from '../../configs/env-vars';
import MobileSidebar from '../molecules/mobile-sidebar';

const GuestWrapperComp = styled.div`
  & .card_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5% 20px;
  }

  & .ant-card {
    height: fit-content;
  }

  & .header_wrapper {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    padding: 22px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .ant-card-body {
    max-width: 600px;
  }

  @media screen and (max-width: 520px) {
    & .card_wrapper {
      padding: 0.5% 0 0;
    }
    & .ant-card {
      border-radius: 0;
      width: 100%;
      box-shadow: none;
    }
    & .ant-card-body {
      padding: 40px 20px !important;
    }
  }
`;

const guestWrapperProps = {
  getQuote: { backColor: '#fff', hideSideBar: true, padding: '0' },
  signIn: { backColor: '#fff', hideSideBar: true, padding: '0' },
  signUp: { backColor: '#fff', hideSideBar: true, padding: '0' },
  forgotPass: { backColor: '#fff', hideSideBar: true, padding: '0' },
  resetPass: { backColor: '#fff', hideSideBar: true, padding: '0' },
  wpQuote: { backColor: '#fff', hideSideBar: true, padding: '0' },
  viewAppDocument: { backColor: '#fff', hideSideBar: true, padding: '0' },
};

const GuestWrapper = ({ children, routeKey }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { backColor, hideSideBar, padding } = guestWrapperProps[routeKey] || {};

  return (
    <GuestWrapperComp>
      <Layout>
        <Header padding='0' back_color='#fff'>
          <Col className='header_wrapper'>
            <a href={WP_ROOT}>
              <img src={Logo} alt='logo' className='logo' />
            </a>
            {!hideSideBar && (
              <div
                className={`burger ${collapsed && 'collapsed'}`}
                onClick={() => setCollapsed(!collapsed)}
              >
                <div className='burger-item' />
                <div className='burger-item' />
                <div className='burger-item' />
              </div>
            )}
          </Col>
        </Header>
        {!hideSideBar && (
          <MobileSidebar setCollapsed={setCollapsed} collapsed={collapsed} customSidebar={true} />
        )}
        <Layout>
          {!hideSideBar && (
            <Sider
              trigger={null}
              collapsible
              theme='light'
              width={300}
              min_width='300px'
              collapsedWidth={56}
              shadow='0px 10px 15px rgba(0, 0, 0, 0.2)'
            >
              <HomeSidebar />
            </Sider>
          )}
          <Layout>
            <Content back_color={backColor} padding={padding}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </GuestWrapperComp>
  );
};

export default memo(GuestWrapper);
