import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from 'constants/routes';
import PageContainer, { getPageComponents } from './getPageContainer';
import { getUser, getUserRole } from './app/services/selector-helpers';
import NotFoundPage from './pages/not-found';

const RouteList = () => {
  const role = useSelector(getUserRole);
  const user = useSelector(getUser);
  const routeList = user ? getPageComponents(role) : routes;
  return (
    <Routes>
      {Object.values(routeList)
        .filter(el => el.pathname)
        .map(route => (
          <Route
            exact
            path={route.pathname}
            key={route.pathname}
            element={
              <Suspense>
                <PageContainer route={route} />
              </Suspense>
            }
          />
        ))}
      <Route path='*' key='not-found' element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouteList;
