import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styled from 'styled-components';
import { GOOGLE_GEOCODE_API_KEY } from '../../../configs/env-vars';

const PlaceAutocompleteInput = ({
  locationValue,
  setLocationValues,
  disabled,
  selectedCountry,
}) => {
  const AutoCompleteWrapper = styled.div`
    .input_parent > div:not(.css-26l3qy-menu) {
      border-radius: 40px;
      background-color: #fff;
    }
    .input_parent > div {
      padding: 5px 10px;
      font-weight: 400;
    }
    .css-tlfecz-indicatorContainer {
      position: relative;
      top: 3px;
      left: 5px;
      svg path {
        d: path(
          'M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'
        ) !important;
      }
    }
  `;

  return (
    <AutoCompleteWrapper>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_GEOCODE_API_KEY}
        apiOptions={{ libraries: ['places'] }}
        autocompletionRequest={{
          componentRestrictions: {
            country: selectedCountry || 'CA',
          },
          types: ['address'],
        }}
        selectProps={{
          className: 'input_parent',
          value: locationValue,
          onChange: setLocationValues,
          isDisabled: disabled,
          placeholder: '',
        }}
      />
    </AutoCompleteWrapper>
  );
};

export default PlaceAutocompleteInput;
