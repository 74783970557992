/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mortgageData: {
    'new-mortgage': {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
    },
    'mortgage-renewal': {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
    },
    'refinance-mortgage': {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
    },
  },
  step: 1,
};

const mortgageSlice = createSlice({
  name: 'mortgage',
  initialState,
  reducers: {
    setMortgageMainData(state, { payload }) {
      state.mortgageData[payload?.type] = {
        ...state.mortgageData[payload?.type],
        ...payload.values,
      };
    },
    setMortgageData(state, { payload }) {
      state.mortgageData[payload?.type][payload?.step] = payload.values;
    },
    setMortgageStep(state, { payload }) {
      state.step = payload;
    },
    resetMortgageData(state) {
      state.mortgageData = initialState?.mortgageData;
      state.step = 1;
    },
  },
});

export const { setMortgageMainData, setMortgageData, setMortgageStep, resetMortgageData } =
  mortgageSlice.actions;
export default mortgageSlice.reducer;
