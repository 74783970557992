/* PLOP_INJECT_EXPORT */
export const calculateRatesEndpoint = '/calculateRates';
export const applicationsEndpoint = '/applications';
export const infoTypesEndpoint = '/types';
export const usersEndpoint = '/users';
export const rateEndpoint = '/rates';
export const typesEndpoint = '/types';
export const userRolesEndpoint = '/users-permissions/roles';
export const documentsEndpoint = '/application-documents';
export const documentsTypesEndpoint = '/document-types/find-required-documents';
export const settingsEndpoint = '/settings';
export const sendInquiryEndpoint = '/sendInquiry';
