import { memo } from 'react';
import styled, { css } from 'styled-components';
import { Button as button } from 'antd';
import { colors } from '../../../constants/colors';

const Button = styled(button)`
  background: ${colors.main_color};
  border-radius: 8px;
  border: none;
  font-weight: 500;
  color: #fff;
  padding: 13px 16px;
  font-size: 14px;
  height: unset;

  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius}!important;
    `}
  ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw};
    `}
  ${props =>
    props.fz &&
    css`
      font-size: ${props.fz};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

    ${props =>
    props.boxShadow &&
    css`
      box-shadow: ${props.boxShadow} !important;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
      & span {
        color: ${props.color};
      }
    `}
  ${props =>
    props.icon_height &&
    css`
      svg {
        height: ${props.icon_height};
      }
    `}
    ${props =>
    props.type === 'primary' &&
    css`
      color: #fff;
      background: ${colors.main_color};
      box-shadow: none;
      border-radius: 100px;
      &:hover {
        background: ${colors.main_color}90;
        color: #fff;
      }
      &:active {
        background: ${colors.main_color};
        color: #fff;
      }
      &:focus {
        background: ${colors.main_color};
        color: #fff;
      }
    `}

    ${props =>
    props.type === 'primary_ghost' &&
    css`
      color: ${colors.main_color};
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: ${colors.main_color};
      }
      &:active {
        background: transparent;
        color: ${colors.main_color};
      }
      &:focus {
        background: transparent;
        color: ${colors.main_color};
      }
    `}
    ${props =>
    props.type === 'white' &&
    css`
      background: #fff;
      color: ${colors.main_color};
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);

      &:hover {
        color: ${colors.main_color}90;
        background: #fff;
      }
      &:active {
        color: ${colors.main_color};
        background: #fff;
      }
      &:focus {
        color: ${colors.main_color};
        background: #fff;
      }
    `}
    ${props =>
    props.type === 'destructive' &&
    css`
      color: #fff;
      background: #ef3e36;
      box-shadow: none;
      border-radius: 100px;
      &:hover {
        background: #ef3e3690;
        color: #fff;
      }
      &:active {
        background: #ef3e36;
        color: #fff;
      }
      &:focus {
        background: #ef3e36;
        color: #fff;
      }
    `}
    ${props =>
    props.type === 'outlined' &&
    css`
      color: ${colors.main_color};
      border: 1px solid ${colors.main_color};
      background: transparent;
      box-shadow: none;
      border-radius: 100px;
      &:hover {
        background: ${colors.main_color}90;
        color: #fff;
      }
      &:active {
        background: ${colors.main_color};
        color: #fff;
      }
      &:focus {
        background: ${colors.main_color};
        color: #fff;
      }
    `}

    ${props =>
    props.type === 'grey_outlined' &&
    css`
      color: #000;
      border: 1px solid #d9d9d9;
      background: #fff;
      box-shadow: none;
      border-radius: 40px;
      padding: 7px 20px 7px 20px;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 1180px) {
        font-size: 15px;
      }

      @media screen and (max-width: 576px) {
        font-size: 3.2vw;
      }

      @media screen and (max-width: 375px) {
        font-size: 11px;
      }
      &:hover {
        background: #fff;
      }
      &:active {
        background: #fff;
      }
      &:focus {
        background: #fff;
      }
    `}

    ${props =>
    props.type === 'red' &&
    css`
      color: #fff;
      background: #ff3030;
      box-shadow: none;

      /* border-radius: 5px !important; */
      &:hover {
        background: #ff303090;
        color: #fff;
      }
      &:active {
        background: #ff303090;
        color: #fff;
      }
      &:focus {
        background: #ff303090;
        color: #fff;
      }
    `}

    ${props =>
    props.type === 'green' &&
    css`
      color: #fff;
      background: #35c5a4;
      box-shadow: none;

      /* border-radius: 5px !important; */
      &:hover {
        background: #35c5a490;
        color: #fff;
      }
      &:active {
        background: #35c5a4;
        color: #fff;
      }
      &:focus {
        background: #35c5a4;
        color: #fff;
      }
    `}

    ${props =>
    props.type === 'green_outlined' &&
    css`
      color: #000;
      background: #fff;
      box-shadow: none;
      border-radius: 10px;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #4eccaf;
        color: #000;
      }
      &:active {
        border: 1px solid #4eccaf;
        color: #000;
      }
      &:focus {
        border: 1px solid #4eccaf;
        color: #000;
      }
    `}

    ${props =>
    props.type === 'green_outlined_app' &&
    css`
      color: #4eccaf;
      border: 1px solid #4eccaf;

      background: transparent;
      box-shadow: none;
      border-radius: 100px;
      &:hover {
        border: 1px solid #4eccaf;
        color: #fff;
        background: #4eccaf;
      }
      &:active {
        border: 1px solid #4eccaf;
        color: #fff;
        background: #4eccaf;
      }
      &:focus {
        border: 1px solid #4eccaf;
        color: #fff;
        background: #4eccaf;
      }
    `}
    ${props =>
    props.type === 'red_outlined_app' &&
    css`
      color: #ff3030;
      border: 1px solid #ff3030;
      background: transparent;
      box-shadow: none;
      border-radius: 100px;

      &:hover {
        border: 1px solid #ff3030;
        color: #fff;
        background: #ff3030;
      }
      &:active {
        border: 1px solid #ff3030;
        color: #fff;
        background: #ff3030;
      }
      &:focus {
        border: 1px solid #ff3030;
        color: #fff;
        background: #ff3030;
      }
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    (props.mt || props.mt === 0) &&
    css`
      margin-top: ${props.mt}px;
    `}
    ${props =>
    (props.mr || props.mr === 0) &&
    css`
      margin-right: ${props.mr}px;
    `}

    ${props =>
    (props.ml || props.ml === 0) &&
    css`
      margin-left: ${props.ml}px;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height};
    `}
  ${props =>
    props.hover_border &&
    css`
      &:hover {
        border-color: ${props.hover_border};
      }
    `}
`;

export default memo(Button);
