/* eslint-disable import/no-cycle */
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { CaretDownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getApplicantsData, getUser } from 'app/services/selector-helpers';
import { colors } from 'constants/colors';
import { Header, Row, Col, Paragraph } from 'components/atoms';
import { removeUser } from 'app/slices/user';
import {
  addNewApplicantData,
  clearApplicationData,
  setApplicationDataIsStored,
} from 'app/slices/application';
import Logo from 'assets/images/logo.png';
import MobileSidebar from './mobile-sidebar';
import { savedAppId } from '../../configs/local-storage';

const HeaderContent = styled.div`
  width: 100%;
  padding: 22px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 75px;
`;

const HeaderContentMobile = styled.div`
  width: 100%;
  padding: 5px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 75px;

  svg {
    cursor: pointer;
  }
`;

export const HeaderWrapperMobile = styled(Header)`
  display: none;
  @media screen and (max-width: 992px) {
    display: block;

    .logo {
      cursor: pointer;
    }
  }
`;

const HeaderWrapperDesktop = styled(HeaderContent)`
  display: block;
  .logo {
    cursor: pointer;
  }
  @media screen and (max-width: 992px) {
    display: none;
    position: sticky;
    top: 0;
  }
`;

const CoApplicantWrapper = styled(Paragraph)`
  cursor: pointer;
  color: ${colors.main_color};
  border-bottom: 2px solid transparent;
  transition: all ease 0.3s;
  &:hover {
    border-bottom: 2px solid ${colors.main_color};
  }
`;

const DashboardHeader = ({
  role,
  hideSideBar,
  openKeys,
  setOpenKeys,
  isSubmitted,
  hideSteps,
  customSidebar,
  successModalOpen,
  setSuccessModalOpen,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const applicantsData = useSelector(getApplicantsData);
  const [collapsed, setCollapsed] = useState(false);

  const userName =
    user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '';
  const isAdmin = role === 'admin';

  const addCoApplicant = () => {
    dispatch(addNewApplicantData());
    navigate(`/identification/${applicantsData?.length}`);
    // setTimeout(() => {
    //   storeApplicationData(user?.id, savedAppId(), () => {}, true, true);
    // }, 500);
  };

  const handleLogout = () => {
    dispatch(removeUser());
    dispatch(clearApplicationData());
    dispatch(setApplicationDataIsStored(false));
    navigate('/sign-in');
  };

  const items = isAdmin
    ? [{ label: 'Log out', key: 'logOut' }]
    : [
        ...(!!savedAppId() && role === 'customer'
          ? [
              {
                label: 'My profile',
                key: 'myProfile',
              },
            ]
          : []),
        { label: 'Log out', key: 'logOut' },
      ];

  const actionList = {
    logOut: () => handleLogout(),
    myProfile: () => navigate('/my-profile'),
  };
  const menuAction = action => {
    const item = action?.[0]?.key;
    actionList[item]();
  };

  return (
    <>
      <HeaderWrapperMobile padding='0' back_color='#fff' position='sticky' top='0'>
        <HeaderContentMobile>
          <Row align='middle' justify='space-between' height='100%'>
            <a href='/'>
              <img src={Logo} alt='logo' className='logo' />
            </a>
            {(!hideSideBar || customSidebar) && (
              <div
                className={`burger ${collapsed && 'collapsed'}`}
                onClick={() => setCollapsed(!collapsed)}
              >
                <div className='burger-item' />
                <div className='burger-item' />
                <div className='burger-item' />
              </div>
            )}
          </Row>
        </HeaderContentMobile>
      </HeaderWrapperMobile>
      <Header padding='0' back_color='#fff'>
        <HeaderWrapperDesktop>
          <Row align='middle' justify='space-between'>
            <a href='/'>
              <img src={Logo} alt='logo' className='logo' />
            </a>
            {user && (
              <>
                {!!savedAppId() && role === 'customer' && !isSubmitted && !hideSteps && (
                  <CoApplicantWrapper mb={0} fw={400} onClick={addCoApplicant}>
                    Add Co-applicant{' '}
                    <PlusCircleOutlined style={{ position: 'relative', top: '2px' }} />
                  </CoApplicantWrapper>
                )}
                <Dropdown
                  overlayStyle={{ zIndex: 99999 }}
                  menu={{
                    items,
                    onClick: (...arg) => {
                      menuAction(arg);
                    },
                    className: 'menuWrapper',
                  }}
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <Col style={{ cursor: 'pointer' }}>
                    {userName} <CaretDownOutlined style={{ color: colors.border_color }} />
                  </Col>
                </Dropdown>
              </>
            )}
          </Row>
        </HeaderWrapperDesktop>
      </Header>
      {(!hideSideBar || customSidebar) && (
        <MobileSidebar
          role={role}
          setCollapsed={setCollapsed}
          handleLogout={handleLogout}
          collapsed={collapsed}
          openKeys={openKeys}
          setOpenKeys={setOpenKeys}
          hideSteps={hideSteps}
          customSidebar={customSidebar}
          isSubmitted={isSubmitted}
          successModalOpen={successModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
        />
      )}
    </>
  );
};

export default memo(DashboardHeader);
