import { IconWrapper } from '../../components/atoms';

const Close = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.9395 12L8.81851 9.879C8.68189 9.73755 8.60629 9.5481 8.608 9.35145C8.60971 9.15481 8.68858 8.9667 8.82764 8.82764C8.9667 8.68859 9.15481 8.60971 9.35145 8.608C9.5481 8.60629 9.73755 8.68189 9.879 8.81851L12 10.9395L14.121 8.81851C14.1902 8.74687 14.2729 8.68974 14.3645 8.65043C14.456 8.61112 14.5544 8.59043 14.654 8.58957C14.7535 8.5887 14.8523 8.60768 14.9445 8.64539C15.0366 8.6831 15.1204 8.73879 15.1908 8.80921C15.2612 8.87963 15.3169 8.96337 15.3546 9.05554C15.3923 9.14771 15.4113 9.24647 15.4104 9.34606C15.4096 9.44564 15.3889 9.54406 15.3496 9.63556C15.3103 9.72706 15.2531 9.80982 15.1815 9.879L13.0605 12L15.1815 14.121C15.2531 14.1902 15.3103 14.2729 15.3496 14.3645C15.3889 14.456 15.4096 14.5544 15.4104 14.654C15.4113 14.7535 15.3923 14.8523 15.3546 14.9445C15.3169 15.0366 15.2612 15.1204 15.1908 15.1908C15.1204 15.2612 15.0366 15.3169 14.9445 15.3546C14.8523 15.3923 14.7535 15.4113 14.654 15.4104C14.5544 15.4096 14.456 15.3889 14.3645 15.3496C14.2729 15.3103 14.1902 15.2531 14.121 15.1815L12 13.0605L9.879 15.1815C9.73755 15.3181 9.5481 15.3937 9.35145 15.392C9.15481 15.3903 8.9667 15.3114 8.82764 15.1724C8.68858 15.0333 8.60971 14.8452 8.608 14.6486C8.60629 14.4519 8.68189 14.2625 8.81851 14.121L10.9395 12Z'
      fill='#ADB5BD'
    />
    <path
      d='M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21V21ZM12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5Z'
      fill='#ADB5BD'
    />
  </svg>
);

const CloseIcon = props => <IconWrapper component={Close} {...props} />;

export default CloseIcon;
