import styled, { css } from 'styled-components';
import { Switch as toggle } from 'antd';
import { colors } from 'constants/colors';

const Switch = styled(toggle)`
  background-color: ${colors.cadet_blue};

  &.ant-switch-checked {
    background-color: ${colors.main_color};
  }

  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.switched_color &&
    css`
      &.ant-switch-checked {
        background-color: ${props.switched_color} !important;
      }
    `}
  ${props =>
    props.type === 'green' &&
    css`
      &.ant-switch {
        border: 1px solid #35c5a4;
        background: transparent;

        & .ant-switch-handle::before {
          background-color: #35c5a4;
          top: -1px;
          bottom: 1px;
        }
      }
      &.ant-switch-checked {
        background: #35c5a4;

        & .ant-switch-handle::before {
          background-color: white;
        }
      }
    `}
`;

export default Switch;
